import firebase from 'firebase/compat/app';
import 'firebase/compat/database';  // For Realtime Database
import 'firebase/compat/firestore'; // For Firestore

// Your Firebase config object from the Firebase Console
const firebaseConfig = {
  apiKey: "AIzaSyBXSdh8nNHf0aOVin0OufCOUiiEGqVdU-8",
  authDomain: "parkingpal-app.firebaseapp.com",
  databaseURL: "https://parkingpal-app-default-rtdb.firebaseio.com",
  projectId: "parkingpal-app",
  storageBucket: "parkingpal-app.appspot.com",
  messagingSenderId: "705116175995",
  appId: "1:705116175995:web:6d2ff019a32d444f3a6cde"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Realtime Database and Firestore
const database = firebase.database();
const firestore = firebase.firestore();

export { database, firestore };
