import React, { Component } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { apiConstants } from '../constants';
import moment from 'moment';
import { firestore } from '../utils/firebase';
import { Timestamp } from "firebase/firestore";


class Home extends React.Component{
    constructor(props) {
        super(props);
        // States and props usage
        this.state = {
            loading: true,
            location_details: null,
            location_prefix: "",
            location_name: "",
            location_logo: "",
            location_id:0,
            allowed_vehicle: [],
            allowed_slot:[],
            formData: {
                vehicle_type: "Car",
                vehicle_no: "",
                slot: "A-1",
                no_hr: 1,
                payment: "10",
                validity: moment().add(1, 'hours').format('DD-MM-YYYY hh:mm A'),
            },
        };
    }
    componentDidMount() {
        this.fetch_details();
    }

    fetch_details = async() => {
        var context = this;
        const queryParameters = new URLSearchParams(window.location.search)
        var location_id = queryParameters.get("location_id")
        if(!location_id){
            // location_id="08BiiviQnGmXY6zEA5HV";
        }
        var ref = firestore.collection('parkings').where("id", "==", location_id);
        await ref.get().then(async function (snapshots) {
            if (snapshots.docs.length > 0) {
                var location_details = snapshots.docs[0].data();
                if (location_details.isKiosk) {
                    var dynamic_price = [];
                    var allowed_vehicle = [];
                    const formData = { ...context.state.formData };
                    if (location_details.parkingType == 2) {
                        allowed_vehicle.push("2 Wheeler");
                        formData['vehicle_type'] = "2 Wheeler";
                    }
                    if (location_details.parkingType == 4) {
                        allowed_vehicle.push("4 Wheeler");
                        formData['vehicle_type'] = "4 Wheeler";
                    }
                    var space = location_details.parkingSpace;
                    var allowed_slot = [];
                    for (var i = 1; i <= space; i++) {
                        allowed_slot.push("A-" + i);
                    }
                    context.setState(
                        {
                            location_details: location_details,
                            location_name: location_details.name,
                            location_id: location_id,
                            allowed_vehicle: allowed_vehicle,
                            allowed_slot: allowed_slot,
                            loading: false,
                            location_logo: (location_details.location_logo ? location_details.location_logo : '../assets/img/Logo.png'),
                            dynamic_price: dynamic_price,
                            formData: formData
                        }, () => {
                            context.change_price();
                            var ref = firestore.collection('booked_parking_order').where("parkingId", "==", location_id).where("bookingEndTime", ">", Timestamp.now());
                            ref.get().then(async function (snapshots) {
                                snapshots.docs.map((data) => {
                                    allowed_slot = allowed_slot.filter(item => item !== data.data().parkingSlotId);
                                })
                                const formData = { ...context.state.formData };
                                formData['slot'] = allowed_slot[0];
                                context.setState(
                                    {
                                        allowed_slot: allowed_slot,
                                        formData: formData
                                    }
                                )
                            });
                        }
                    );
                }
            }
        });
        
    }

    handleChange = ({ currentTarget: input }) => {
        const formData = { ...this.state.formData };
        formData[input.name] = input.value;
        this.setState({ formData },() => {
            this.change_price()
        });
    };
    minusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) - 1;
        count = count < 1 ? 1 : count;
        formData['no_hr'] = count;
        this.setState({ formData },() => {
            this.change_price()
        });
    };
    plusNumber = () => {
        const formData = { ...this.state.formData };
        var count = parseInt(this.state.formData.no_hr) + 1;
        if (count <= 24) {
            formData['no_hr'] = count;
            this.setState({ formData },() => {
                this.change_price()
            });
        }
    };
    change_price = () => {
        const formData = { ...this.state.formData };
        var no_hr = this.state.formData.no_hr;
        var vehicle_type = this.state.formData.vehicle_type;
        var location_details = this.state.location_details;
        var dynamic_price = this.state.dynamic_price;
        var payment = 0;
        payment = location_details['perHrPrice']*no_hr;
        if (dynamic_price[vehicle_type]) {
            var max_cost = 0;
            var costing = 0;
            for (var cost of dynamic_price[vehicle_type]) {
                if (no_hr <= cost['max_hr'] && costing == 0) {
                    costing=cost['price'];
                }
                if (cost['max_hr']==-1) {
                    max_cost = cost['price'];
                }
            }
            if (costing == 0 && max_cost!=0) {
                payment = max_cost;
            }
            if (costing != 0) {
                payment = costing;
            }
        }
        formData['payment'] = payment;
        formData['validity'] = moment().add(no_hr, 'hours').format('DD-MM-YYYY hh:mm A');
        this.setState({ formData });
    };
    saveUserVehicleBookingDetails = async (user_id,razorpay_order_id,razorpay_payment_id) => {
        const context = this;
        var ref = firestore.collection('user_vehicles').where("vehicleNumber", "==", context.state.formData.vehicle_no);
        ref.get().then(async function (snapshots) {
            var vehicle_details = {};
            if (snapshots.docs.length > 0) {
                vehicle_details = snapshots.docs[0].data();
            } else {
                vehicle_details = {
                    "userId":user_id,
                    "vehicleBrand": {
                        "name":"Kiosk Brand",
                        "enable": true,
                    },
                    "vehicleModel": {
                        "name": "Kiosk Model",
                        "enable": true,
                        "image":"https://admin.parkingpal.app/images/car_model_1.png"
                    },
                    "vehicleNumber": context.state.formData.vehicle_no
                };
                await firestore.collection('user_vehicles').add(vehicle_details).then(async function (docRef) {
                    vehicle_details.id = docRef.id;
                    await firestore.collection('user_vehicles').doc(docRef.id).update({ id: docRef.id }).then(() => {
                    })
                });
            }
            var booking_details = {
                "adminCommission": {
                    "amount": "0",
                    "enable": true,
                    "type": "percentage"
                },
                "bookingFormattedDate":moment().format('DD-MM-YYYY'),
                "bookingDate":Timestamp.fromDate(moment(moment(),'DD-MM-YYYY').toDate()),
                "bookingEndTime":Timestamp.fromDate(moment(moment().add(context.state.formData.no_hr, 'hours').format('DD-MM-YYYY hh:mm A'),'DD-MM-YYYY hh:mm A').toDate()),
                "bookingStartTime":Timestamp.fromDate(moment(moment().format('DD-MM-YYYY hh:mm A'),'DD-MM-YYYY hh:mm A').toDate()),
                "coupon":{
                    "amount":null,
                    "code":null,
                    "enable":null,
                    "id":null,
                    "title":null,
                    "type":null,
                    "validity":null,
                },
                "createdAt":Timestamp.now(),
                "duration":context.state.formData.no_hr,
                "parkingDetails":context.state.location_details,
                "parkingId":context.state.location_id,
                "parkingSlotId":context.state.formData.slot,
                "paymentCompleted":true,
                "paymentType":"RazorPay",
                "status":"placed",
                "subTotal":context.state.formData.payment,
                "vehicleType":context.state.formData.vehicle_type,
                "bookingType":"Kiosk",
                "taxList":[],
                "updateAt":Timestamp.now(),
                "userId":user_id,
                "userVehicle":vehicle_details,
                "razorpayOrderID":razorpay_order_id,
                "razorpayPaymentID":razorpay_payment_id
            }

            await firestore.collection('booked_parking_order').add(booking_details).then(async function (docRef) {
                booking_details.id = docRef.id;
                await firestore.collection('booked_parking_order').doc(docRef.id).update({ id: docRef.id }).then(() => {
                })
            });
            window.location = "/thankyou?order_id="+booking_details.id;

        })
    }
    submitForm = async () => {
        let pattern = /[A-Za-z]{2}[0-9]{2}[A-Za-z]{1,2}[0-9]{4}/;
        if (this.state.formData.vehicle_no === "") {
            toast.error("Please Enter Vehicle Number!")
        } else if (!pattern.test(this.state.formData.vehicle_no)) {
            toast.error("Please Enter Correct Vehicle Number!(AA01AB1234)")
        } else {
            const context = this;
            this.setState({ loading: true });
            var paymentRef = firestore.collection('settings').doc('payment');
            paymentRef.get().then(async function (paymentSnapshots) {
                var payment = paymentSnapshots.data().razorpay;
                // console.log(payment);
                payment.razorpayKey='rzp_test_C80TZB02e0PiA3';
                payment.razorpaySecret='6X3OmYK8Mq0eMAwF0kgR6gvW';
                let formData = new FormData();
                formData.append("amount", (context.state.formData.payment*100));
                formData.append("vehicle_type", context.state.formData.vehicle_type);
                formData.append("currency", "INR");
                formData.append("razorpaykey", payment.razorpayKey);
                formData.append("razorPaySecret", payment.razorpaySecret);
                formData.append("isSandBoxEnabled", payment.isSandbox.toString());
                formData.append("receipt_id", (Math.random().toString(36).substring(2) + Date.now().toString()));
                
                axios.post(apiConstants.base_url+"payments/razorpay/createorder", formData)
                    .then(function (response) {
                        console.log(response.data);
                        if (response.data.status=="created") {
                            var options = {
                                key: payment.razorpayKey,
                                amount: response.data.amount,
                                currency: response.data.currency,
                                name: "Parkingpal",
                                description: "Parkingpal",
                                image: 'https://kiosk.parkingpal.app/assets/img/Logo.png',
                                order_id: response.data.id,
                                theme: {
                                  color: "#F37254"
                                },
                            };
                            context.setState({ loading: false });
                            options["callback_url"] = "";
                            options["handler"] = async (response) => {
                                try {
                                    context.setState({ loading: true });
                                    const paymentId = response.razorpay_payment_id;
                                    console.log(response);
                                    let formData = new FormData();
                                    var razorpay_order_id = response.razorpay_order_id;
                                    var razorpay_payment_id = response.razorpay_payment_id;
                                    formData.append("razorpaykey", payment.razorpayKey);
                                    formData.append("razorPaySecret", payment.razorpaySecret);
                                    formData.append("razorpay_payment_id",response.razorpay_payment_id)
                                    formData.append("razorpay_order_id",response.razorpay_order_id)
                                    formData.append("razorpay_signature",response.razorpay_signature)
                                    axios.post(apiConstants.base_url+"payments/razorpay/verifyPayment", formData)
                                    .then((response1) => {
                                        context.setState({ loading: false });
                                        if (response1['data']['status'] == "success") {
                                            var ref = firestore.collection('users').where("phoneNumber", "==", response1['data']['user_details']['phoneNumber']);
                                            ref.get().then(async function (snapshots) {
                                                if (snapshots.docs.length > 0) {
                                                    var user_details = snapshots.docs[0].data();
                                                    context.saveUserVehicleBookingDetails(user_details.id,razorpay_order_id,razorpay_payment_id)
                                                } else {
                                                    response1['data']['user_details']['createdAt'] = Timestamp.now();
                                                    firestore.collection('users').add(response1['data']['user_details']).then(async function (docRef) {
                                                        await firestore.collection('users').doc(docRef.id).update({ id: docRef.id }).then(() => {
                                                        })
                                                        context.saveUserVehicleBookingDetails(docRef.id,razorpay_order_id,razorpay_payment_id)
                                                    });
                                                }
                                            })
                                        }
                                        else {
                                            toast.error(response1['data']['message']);
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })
                                    .finally(function () {
                                    });


                                } catch (err) {
                                    toast.error("Payment Failed");
                                }
                            };
                            options["modal"] = {
                                "ondismiss": (context) => {
                                    console.log('Checkout form closed');
                                }
                            };
                            const rzp1 = new window.Razorpay(options);
                            rzp1.open();
                        } else {
                            toast.error("Please Try Again!");
                            context.setState({ loading: false });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function () {
                    });
                }
            );
        }
    }
    render() {
        return ( 
            <div>
                <main>
                    <div className="p-2 d-flex align_item_center" style={{width: "fit-content",margin: "auto",position: "fixed",top: 0,width: "100%",justifyContent: "center",background: "white",boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"}}>
                        <img className="d-block" src="../assets/img/Logo.png" alt="" width="160" />
                        <a style={{position: "absolute", right: "12px", display: "flex", flexDirection: "column"}}>
                            <HelpOutlineIcon />
                            info
                        </a>
                    </div>
                </main>
                <div className="container" style={{marginTop: "56px"}}>
                    <main>
                        <div className="p-2 d-flex align_item_center">
                            {/* <img className="d-block mx-auto" srcSet={this.state.location_logo} src={this.state.location_logo} alt="" width="82"></img> */}
                        </div>
                        <div className="pb-2 d-flex align_item_center text-center">
                            <h2 style= {{ flex: 1, fontSize: "20px" }} className="mb-1"><b>Your are paying for</b></h2>
                        </div>
                        <div className="m-2 mt-0 d-flex align_item_center text-center" style={{ background:"#4caf50",color: "white" }}>
                            <h5 style={{ marginBottom: "0", marginLeft: "0.25rem" }}><b>LOCATION</b></h5>
                            {/* <h1 style={{ flex: 1,marginBottom: "0"}}><b>{ this.state.location_prefix }</b></h1> */}
                            <h5 style={{ marginBottom: "0", marginRight: "0.375rem", whiteSpace: "normal", flex: 1 }}><b>{this.state.location_name}</b></h5>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="vehicle_type" className="form-label">Type of Vehicle</label>
                            </div>
                            
                            <div className="col-7">
                                <select
                                    className="form-select"
                                    id="vehicle_type"
                                    name="vehicle_type"
                                    onChange={this.handleChange}
                                    value={this.state.formData.vehicle_type}
                                >
                                    {this.state.allowed_vehicle.map(vehicel => (
                                            <option value={vehicel}>{vehicel}</option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="vehicle_no" className="form-label">Vehicle No</label>
                            </div>
                            
                            <div className="col-7">
                                <input type="text" style={{ textTransform: "uppercase" }} className="form-control" maxlength="10" id="vehicle_no" name="vehicle_no" placeholder="" value={this.state.formData.vehicle_no} onChange={this.handleChange} />
                                <span style={{ fontSize: "10px", marginBottom: "2px" }}>For e.g. MH12JE1293</span>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="slot" className="form-label">Slot</label>
                            </div>
                            
                            <div className="col-7">
                                <select
                                    className="form-select"
                                    id="slot"
                                    name="slot"
                                    onChange={this.handleChange}
                                    value={this.state.formData.slot}
                                >
                                    {this.state.allowed_slot.map(vehicel => (
                                            <option value={vehicel}>{vehicel}</option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                                <label for="no_hr" className="form-label">Enter No of hrs</label>
                            </div>
                            
                            <div className="col-7">
                                <div className="input-group">
                                <span className="minus number_span input-group-text" onClick={this.minusNumber} >-</span>
                                <input type="text" className="form-control number_input" id="no_hr" name="no_hr" value={this.state.formData.no_hr} min="1" max="24" style={{ pointerEvents: "none"}} />
                                <span className="plus number_span input-group-text" onClick={this.plusNumber}>+</span>
                                </div>
                            </div>
                        </div>

                        <div className="row p-1 g-3 align_item_center">
                            <div className="col-5">
                            </div>
                            <div className="col-7 text-center">
                                <label for="no_hr" className="form-label mb-0" style={{ fontSize: "14px" }}>Valid Till</label>
                                <span id="till" style={{ fontSize: "12px", marginBottom: "2px" }}> {this.state.formData.validity}</span>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="my-1 py-0 p-1" style={{backgroundColor: '#012e5d'}}>
                    <div className="container">
                        <p style={{color: "white",marginBottom: "0px"}}>Payment Summary </p>
                    </div>
                </div>
                <div className="container">
                    <main>
                        <div className="row p-1 g-3 align_item_center">
                        <div className="col-5">
                            <label for="location" className="form-label">Parking Fee</label>
                        </div>
                        <div className="col-7">
                            <p style={{marginBottom: "0px"}}>Rs. <span id="payment_amount">{this.state.formData.payment} </span></p>
                            <input type="hidden" id="payment" name="payment" value={this.state.formData.payment}/>
                        </div>
                        </div>
                        <div className="row g-3 py-2 pb-0 align_item_center" style={{justifyContent: "center"}}>
                            <button className="btn btn-primary btn-lg" style={{ width: "fit-content", borderRadius: "24px", padding: "10px 46px", color: "white", background: "#4caf50", border: "white", fontWeight: 700, fontSize: "20px" }}
                                onClick={ this.submitForm}>PAY NOW</button>
                        </div>
                    </main>
                </div>
                <div className="container" style={{marginBottom: "72px"}}>
                    <main>
                        <div className="p-2 pt-0 pb-0 text-center" style={{}}>
                            <p className="lead" style={{ fontSize: "12px", marginTop: "4px", marginBottom: "0px" }}>
                                If an officer checks your number plate, they will be notified that your parking is validated
                            </p>
                            <p className="lead" style={{fontSize: "12px",color: "red",marginTop: "4px",marginBottom: "0px"}}>
                                Park at your own risk. Company /corporation not responsible for any loss or damage
                            </p>
                        </div>
                    </main>
                </div>
                <main>
                    <div className="p-2 d-flex align_item_center" style={{ width: "fit-content", margin: "auto", position: "fixed", bottom: 0, width: "100%", justifyContent: "center", background: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px", borderTop: "1px solid #0000002b" }}>
                        <div style={{display: "flex", width: "100%"}}>
                            <a href="tel:8888006666" style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "black",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/2.png" style={{ width:"20px"}} />
                                Call Helpline
                            </a>
                            <a href="#" style={{display: "flex", flex: 1, textDecoration: "none",alignItems: "center", color: "#4caf50",flexDirection: "column", fontSize:"12px"}}>
                                <img src="../assets/img/icons/5.png" style={{ width:"20px"}} />
                                Home Page
                            </a>
                        </div>
                    </div>
                </main>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    theme="light"
                />
                { this.state.loading?<Loading loading background="#ffffff80" loaderColor="rgb(76 175 80)" />:null}
            </div>
        );
    }
}
export default Home;